import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { JwtService } from "../services/auth/jwt.service";
import { Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = null;

    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };


    if (isPlatformBrowser(this.platform)) {
      token = this.jwtService.getToken();
    }

    if (token && (token.length > 3) && (!req.url.includes('amazonaws'))) {
      // @ts-ignore
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    // Delete content-type for it to be set automatically as multipart/form-data with boundary
    isPlatformBrowser(this.platform);
    {
      if (req.body) {
        // @ts-ignore
        delete headersConfig['Content-Type'];
      }
    }

    const request = req.clone({setHeaders: headersConfig});
    return next.handle(request);
  }
}
