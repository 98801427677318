/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import './vendors/extensions/dayjs.extension';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { environment } from "./environments/environment";

if ((!environment.logging) && window) {
  window.console.log = window.console.info = window.console.warn = window.console.error = () => {};
}

// register Swiper custom elements
register();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});


