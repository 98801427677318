import { Component, OnDestroy } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  OperatorFunction,
  Subject,
  switchMap, takeUntil
} from "rxjs";
import { tap } from "rxjs/operators";
import { SearchService } from "../../../../core/services/application/search.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements OnDestroy {

  destroyed$ = new Subject();

  public model: any;
  searching = false;
  searchFailed = false;


  search: OperatorFunction<string, readonly string[]>;

  constructor(
    private service: SearchService,
    private router: Router,
  ) {
    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap((term) =>
          this.service.search(term).pipe(
            takeUntil(this.destroyed$),
            map((r) => r.data),
            tap(() => (this.searchFailed = false)),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }),
          ),
        ),
        tap(() => (this.searching = false)),
      );
  }

  onSelect(e: any) {
    e.preventDefault();
    if (e?.item?.type === 'blog') {
      this.router.navigateByUrl('/dashboard/blog/' + e.item.object.slug);
    } else if (e?.item?.type === 'video') {
      this.router.navigateByUrl('/dashboard/videos/detail/' + e.item.object.id);
    } else if (e?.item?.type === 'live') {
      this.router.navigateByUrl('/dashboard/videos/live/' + e.item.object.id);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
