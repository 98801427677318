import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Router } from "@angular/router";
import { cdnUri } from "../../../core/helpers/app.helper";
import {isPlatformBrowser} from '@angular/common';
import { environment } from "../../../../environments/environment";
@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrl: './landing-layout.component.scss'
})
export class LandingLayoutComponent implements OnInit {
  isMenuCollapsed = true;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  scrollToSection(sectionId?: string): void {

    if (this.router.url == '/') {
      // @ts-ignore
      const offsetTop = (document?.getElementById(sectionId)?.offsetTop) - 100;

      document?.getElementById('body')?.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    } else {
      this.router.navigate(['/'], {fragment: sectionId});
    }

  }

  protected readonly cdnUri = cdnUri;

  ngOnInit() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId) && environment.liveChatIsActive) {
        this.loadScript('/assets/scripts/exairon-messenger.js');
      }
    });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
