<!--bg-level-primary-->
<div class="net-overview">
  <div class="bg">
    <div class="selectors">
      <p class="fs-normal fw-700 clickable" [ngClass]="{ 'color-gray-950': selectedExam === 'tyt', 'color-gray-300': selectedExam !== 'tyt' }" (click)="selectExam('tyt')">TYT</p>
      <p class="fs-normal fw-700 clickable" [ngClass]="{ 'color-gray-950': selectedExam === 'ayt', 'color-gray-300': selectedExam !== 'ayt' }" (click)="selectExam('ayt')">AYT</p>
    </div>

    <ng-container *ngIf="averages">
      <ng-container *ngIf="selectedExamIsExist(); else: noDataTemplate">
        <ng-container *ngIf="(level$ | async) as level">
          <div class="level-bg level-{{levelData?.key}}">
            <div class="steps position-relative w-100">
              <ng-container *ngFor="let step of steps;">
                <div class="net-item">
                  <div class="value">{{step}}</div>
                  <div class="description">Net</div>
                </div>
              </ng-container>

              <div class="level-indicator d-flex align-items-center justify-content-end" [style.width]="'calc(' + positionPercentage + '% + 20px)'">
                <img class="img-fluid" src="/assets/theme/components/dashboard/suitcase.svg" alt="...">
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noDataTemplate>
        <div class="level-bg no-color">
          <div class="steps position-relative w-100 d-flex align-items-center justify-content-center">
            <p class="color-gray-950 fw-800 fs-normal text-center clickable" (click)="goToCreateExam()">
              {{'home.indicator_no_exam'|translate}}
            </p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
