import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { PushNotificationService } from "./core/services/notification/push-notification.service";
import { ThemeService } from "./core/services/theme.service";
import { isPlatformBrowser } from "@angular/common";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter } from "rxjs";
import { ScriptsService } from "./core/services/tracker/scripts.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'gray-frontend';
  isBrowser: boolean;

  constructor(
    private translate: TranslateService,
    private pushNotification: PushNotificationService,
    private themeService: ThemeService,
    private router: Router,
    private modalService: NgbModal,
    private scriptsService: ScriptsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          // Dismiss all open modals when the route changes
          this.modalService.dismissAll("Route change");
        }
      });

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((r) => {
        const eventInfo = r as NavigationEnd;
        if (!eventInfo.url.includes('dashboard')) {
          document?.getElementById('body')?.scrollTo(0, 0);
        }

      });

    }
  }

  ngOnInit(): void {
    this.translate.addLangs(['tr', 'en']);
    this.translate.use('tr');
    this.scriptsService.loadCookieBotScript();
  }

  changeColor() {
    console.log(this.themeService.current);
    const color = (this.themeService.current == 'default') ? 'green' : 'default';
    this.themeService.current = color;
  }

  ngAfterViewInit(): void {
  }
}
