import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, Observable, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { ExamState } from "../../../../core/ngrx/reducers/exam.reducer";
import { selectExams } from "../../../../core/ngrx/selectors/exam.selectors";
import { IExam } from "../../../../core/models/exam/exam.model";
import { LevelState } from "../../../../core/ngrx/reducers/level.reducer";
import { ILevel } from "../../../../core/models/user/level.model";
import { selectLevels } from "../../../../core/ngrx/selectors/level.selectors";
import { EExamKey } from "../../../../core/enums/exam-key.enum";
import lodash from 'lodash';
import { ExamScoreService } from "../../../../core/services/exam/exam-score.service";
import { IExamScore } from "../../../../core/models/exam/exam-score.model";
import { Router } from "@angular/router";
import { Ability } from "@casl/ability";
import { PremiumAccessService } from "../../../../shared/services/premium-access.service";

interface IndicatorExam {
  examId: number,
  examKey: string,
  avgScore?: number,
  levelId?: number,
  currentLevel?: ILevel,
  preLevel?: ILevel,
  postLevel?: ILevel
}

@Component({
  selector: 'app-level-indicator',
  templateUrl: './level-indicator.component.html',
  styleUrl: './level-indicator.component.scss'
})
export class LevelIndicatorComponent implements OnInit, OnDestroy {
  isLoading = false;
  destroyed$ = new Subject();
  exams$: Observable<IExam[] | null>;
  exams?: IExam[];
  levels$: Observable<ILevel[] | null>;
  levels?: ILevel[];
  averageScores: IExamScore[] = [];
  indicatorExams: IndicatorExam[] = [];
  selectedIndicatorExam?: IndicatorExam;
  examKey = EExamKey;
  percentPosition = 10;
  indicatorElements: number[] = [];
  indicatorPreElements: number[] = [];
  indicatorPostElements: number[] = [];

  constructor(
    private store: Store<ExamState | LevelState>,
    private examScoreService: ExamScoreService,
    private router: Router,
    private ability: Ability,
    private premiumAccessService: PremiumAccessService,
  ) {
    this.exams$ = store.select(selectExams);
    this.levels$ = store.select(selectLevels);
  }

  ngOnInit() {
    this.exams$.pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        this.exams = r as IExam[];
        if (this.exams) {
          this.getLevels();
        }
      });
  }

  getLevels() {
    this.levels$.pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        this.levels = r as ILevel[];
        if (this.levels) {
          this.indicatorExams = [];
          this.getAverageScores();
        }
      });
  }

  getAverageScores() {
    this.examScoreService.getAverageScores()
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.averageScores = r.data;
        this.setExamScores();
      })
  }

  setExamScores() {
    const exams = this.exams as IExam[];
    for (const exam of exams) {
      const averageScore = this.averageScores.find(s => s.examId === exam.id);
      const currentLevel = this.levels?.find(l => l.id === averageScore?.levelId);
      let preLevel = undefined;
      let postLevel = undefined;
      if (currentLevel) {
        postLevel = this.levels?.find(l => l.rank === (currentLevel.rank - 1));
        preLevel = this.levels?.find(l => l.rank === (currentLevel.rank + 1));
      }
      this.indicatorExams.push({
        examId: exam.id,
        examKey: exam.examKey,
        avgScore: averageScore?.lastAvgScore ?? undefined,
        levelId: currentLevel?.id,
        currentLevel: currentLevel,
        preLevel,
        postLevel,
      })
    }

    this.changeSelected(EExamKey.TYT);
  }

  changeSelected(examKey: EExamKey) {
    const selectedExam = this.indicatorExams.find(e => e.examKey === examKey);

    const startScore = selectedExam?.currentLevel?.minScore ?? 0;
    const endScore = selectedExam?.postLevel?.minScore ?? 120;
    const step = 5;

    const numberArray: number[] = lodash.range(startScore, endScore + 1, step);
    const currentScore = selectedExam?.avgScore;
    if (currentScore) {
      const currentIndex = numberArray.findIndex(number => Number(number) >= currentScore);
      if (currentIndex >= 0) {
        const percentPosition = ((currentIndex + 1) / numberArray.length) * 100;
        this.indicatorPreElements = numberArray.slice(0, currentIndex);
        this.indicatorPostElements = numberArray.slice(currentIndex + 1);
        this.percentPosition = percentPosition;
      }
    }

    this.indicatorElements = numberArray;
    this.selectedIndicatorExam = selectedExam;
  }

  goToCreateExam() {
    if (this.ability.can('access', 'PREMIUM_CONTENT')) {
      this.router.navigate(['/dashboard/exam-analysis/', this.selectedIndicatorExam?.examKey], {
        queryParams: {
          create: 1,
        }
      });
    } else {
      this.premiumAccessService.openPremiumContentModal();
    }

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
