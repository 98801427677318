import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { UnreadNotificationState } from "../../../../core/ngrx/reducers/unread-notification.reducer";
import { selectUnreadNotifications } from "../../../../core/ngrx/selectors/unread-notification.selectors";
import { isPlatformBrowser } from "@angular/common";
import { NotificationService } from "../../../../core/services/notification/notification.service";
import { setUnreadNotification } from "../../../../core/ngrx/actions/unread-notification.action";

@Component({
  selector: 'app-nav-notifications',
  templateUrl: './nav-notifications.component.html',
  styleUrl: './nav-notifications.component.scss'
})
export class NavNotificationsComponent implements OnInit, OnDestroy {
  unreadNotification$: Observable<number | null>;
  unreadNotification?: number | null;
  isBrowser: boolean;
  destroyed$ = new Subject();

  constructor(
    private store: Store<UnreadNotificationState>,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.unreadNotification$ = store.select(selectUnreadNotifications);
  }

  ngOnInit() {

    if (this.isBrowser) {

      this.unreadNotification$.pipe(takeUntil(this.destroyed$))
        .subscribe(unread => {
          this.unreadNotification = unread;

          if (this.unreadNotification == null) {
            this.loadUnreadNotifications();
          }

        });

    }

  }

  loadUnreadNotifications() {
    this.notificationService.getUnreadNotificationCount()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        this.store.dispatch(setUnreadNotification(r.data));
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
