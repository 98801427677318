<div class="d-flex w-100 h-100" style="background: var(--gray-50, #F6F6F6);">

  <!-- Off-canvas menu for mobile -->
  <ng-template #content let-offcanvas>
    <div class="offcanvas-body ">
      <div class="sidebar-mobile">

        <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 35px);">
          <div>
            <div class="w-100">
              <app-user-profile></app-user-profile>
            </div>

            <div class="w-100 mt-5">
              <div class="col-12">
                <ng-container *ngFor="let p of appPages; let i = index;">
                  <div
                    class="nav-item d-flex w-100 align-items-center color-gray-300"
                    [class.active]="p.active" (click)="goToPage(p)">
                    <gray-icon *ngIf="p.grayIcon" icon="{{p.icon}}"></gray-icon>
                    <i *ngIf="!p.grayIcon" class="{{p.icon}}"></i>
                    <p class="ms-2 fs-large fw-500">{{p.title}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="campaigns-mobile py-2 px-3">
            <a
              class="clickable color-gray-50 fw-800 fs-normal w-100 d-block d-flex align-items-center"
              routerLink="/dashboard/campaigns">
              <img src="assets/img/sale-box2.png" alt="campaigns" class="me-1">
              {{'campaigns.all_campaigns'|translate}}
            </a>
          </div>
        </div>


      </div>

    </div>
  </ng-template>

  <div class="sidebar d-none d-md-flex flex-column justify-content-between" [class.collapsed]="menuIsCollapsed">
    <div class="w-100">
      <div class="col-12">
        <div class="d-flex flex-column justify-content-between align-items-center">
          <div class="logo-container align-self-center my-2">
            <img [src]="(!menuIsCollapsed) ? cdnUri('assets/logo/gks-dark-2.svg') : cdnUri('assets/logo/gks-dark-symbol.svg')"
                 alt="Logo">
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 h-100" [class.mt-5]="!menuIsCollapsed" [class.mt-3]="menuIsCollapsed">
      <div class="col-12">
        <ng-container *ngFor="let p of appPages; let i = index;">
          <div
            class="nav-item d-flex w-100 align-items-center color-gray-300"
            [class.active]="p.active" [class.mb-1]="menuIsCollapsed" (click)="goToPage(p)">
            <gray-icon *ngIf="p.grayIcon" icon="{{p.icon}}"></gray-icon>
            <i *ngIf="!p.grayIcon" class="{{p.icon}}"></i>
            <p *ngIf="(!p.active && menuIsCollapsed)|| (!menuIsCollapsed)" [class.fs-small]="menuIsCollapsed"
               [class.ms-2]="!menuIsCollapsed" [class.fs-large]="!menuIsCollapsed" class=" fw-500">{{p.title}}</p>
          </div>
        </ng-container>
      </div>
    </div>

    <div>
      <app-gray-button
        *ngIf="!user?.planTypeId"
        (click)="goToPayment()"
        [text]="menuIsCollapsed ? '' : ('account.be_premium'|translate)"
        padding="px-3 py-3"
        customClass="d-flex align-items-center justify-content-evenly"
        color="primary" preIcon="premium" [weight]="500"
      ></app-gray-button>

      <div class="clickable campaigns text-center pt-4 pb-2 px-3" *ngIf="!menuIsCollapsed" (click)="goToCampaigns()">
        <img src="assets/img/sale-box2.png" alt="campaigns">
        <p class="color-gray-50  fw-900 fs-normal mb-4" [innerHTML]="'campaigns.campaigns_menu'|translate"></p>
        <a class="clickable color-gray-50 fw-800 fs-xsmall px-3 py-2 w-100 d-block text-center">
          {{'campaigns.all_campaigns'|translate}}
        </a>
      </div>

      <div class="campaigns-collapsed p-2 mt-2 clickable" *ngIf="menuIsCollapsed" (click)="goToCampaigns()">
        <img src="assets/img/sale-box2.png" alt="campaigns">
      </div>
    </div>
  </div>
  <div class="main-container" id="main-container">
    <div class="container">
      <div class="content-wrapper d-flex flex-column w-100 h-100 py-3 ">
        <div class="row mb-4" *ngIf="!menuIsCollapsed">
          <div class="col-2 col-md-4">
            <button class="btn d-md-none" type="button" (click)="open(content)">
              <gray-icon icon="menu" style="width: 20px"></gray-icon>
            </button>
            <app-user-profile class="d-none d-md-block"></app-user-profile>
          </div>
          <div class="col-8 px-0 col-md-5">
            <app-level-indicator-two></app-level-indicator-two>
          </div>
          <div class="col-2 col-md-3 d-flex align-items-center ps-1 ps-md-3">

            <app-nav-notifications></app-nav-notifications>

            <app-search-input class="d-none d-md-block"></app-search-input>


          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</div>
