import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { isPlatformBrowser } from "@angular/common";

declare var CookieConsent: any;
declare var Cookiebot: any;

@Injectable()
export class ScriptsService {
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  loadCookieBotScript() {
    if ((typeof document !== 'undefined') && !document.getElementById('Cookiebot') && this.isBrowser) {
      const script = document.createElement('script');
      script.id = 'Cookiebot';
      script.src = 'https://consent.cookiebot.com/uc.js';
      script.setAttribute('data-cbid', environment.cookiebotCbid);
      script.setAttribute('data-blockingmode', 'manual');
      script.type = 'text/javascript';
      script.onload = () => {
        this.checkConsentAndLoadScripts();
        this.addConsentChangeListener();
      };
      document.head.appendChild(script);
    } else {
      this.checkConsentAndLoadScripts();
      this.addConsentChangeListener();
    }
  }


  private addConsentChangeListener() {
    if ((typeof window !== 'undefined') && this.isBrowser) {
      window.addEventListener('CookiebotOnAccept', () => {
        this.loadScriptsBasedOnConsent(CookieConsent);
      });

      window.addEventListener('CookiebotOnDecline', () => {
        this.loadScriptsBasedOnConsent(CookieConsent);
      });

      // CookiebotOnAccept
    }

    if ((typeof Cookiebot !== 'undefined') && this.isBrowser) {
      Cookiebot.onconsentchange = () => {
        this.loadScriptsBasedOnConsent(CookieConsent);
      };
    }
  }

  private checkConsentAndLoadScripts() {
    if ((typeof CookieConsent !== 'undefined') && this.isBrowser) {
      this.loadScriptsBasedOnConsent(CookieConsent);
    } else {
      if ((typeof window !== 'undefined') && this.isBrowser) {
        window.addEventListener('CookieConsent', () => {
          this.loadScriptsBasedOnConsent(CookieConsent);
        });
      }
    }
  }


  private loadScriptsBasedOnConsent(consent: any) {
    if (consent && consent?.consent?.marketing) {
      this.loadMetaPixel();
      this.loadGoogleTagManager();
    } else {
      this.removeScripts();
    }
  }


  private loadMetaPixel() {

    if ((!document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]')) && this.isBrowser) {
      // @ts-ignore
      (function (f, b, e, v, n, t, s) {
        // @ts-ignore
        if (f.fbq) return;
        // @ts-ignore
        n = f.fbq = function () {
          // @ts-ignore
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        // @ts-ignore
        if (!f._fbq) { // @ts-ignore
          f._fbq = n;
        }
        // @ts-ignore
        n.push = n;
        // @ts-ignore
        n.loaded = !0;
        // @ts-ignore
        n.version = '2.0';   // @ts-ignore
        n.queue = [];
        // @ts-ignore
        t = b.createElement(e);
        // @ts-ignore
        t.async = !0;
        // @ts-ignore
        t.src = v;
        // @ts-ignore
        s = b.getElementsByTagName(e)[0];
        // @ts-ignore
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));

      fbq('init', '1071844977740186');
      fbq('track', 'PageView');
    }
  }

  private loadScript(src: string, onload?: () => void) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.type = 'text/javascript';
    if (onload) {
      script.onload = onload;
    }
    document.head.appendChild(script);
  }

  private loadGoogleTagManager() {
    if (!document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=AW-16633345998"]')) {
      this.loadScript('https://www.googletagmanager.com/gtag/js?id=AW-16633345998');
      this.loadScript('https://www.googletagmanager.com/gtag/js?id=G-GR47TV4KVX', () => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        function gtag() { // @ts-ignore
          dataLayer.push(arguments);
        }

        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', 'AW-16633345998');
        // @ts-ignore
        gtag('config', 'G-GR47TV4KVX');
      });
    }
  }

  private removeScripts() {
    // Remove Meta Pixel script
    const fbScript = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
    if (fbScript) {
      // @ts-ignore
      fbScript.parentNode.removeChild(fbScript);
    }

    // Remove Google Tag Manager scripts
    const gtmScripts = [
      document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=AW-16633345998"]'),
      document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-GR47TV4KVX"]')
    ];

    gtmScripts.forEach(script => {
      if (script) {
        // @ts-ignore
        script.parentNode.removeChild(script);
      }
    });

    // Clear dataLayer
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer = [];
    }
  }
}
