import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, take } from "rxjs";
import { IUser } from "../models/user/user.model";
import { Store } from "@ngrx/store";
import { UserState } from "../ngrx/reducers/user.reducer";
import { selectCurrentUser } from "../ngrx/selectors/user.selectors";
import { isPlatformBrowser } from "@angular/common";
import { map } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class EmailVerifyGuard implements CanActivate {
  user$: Observable<IUser | null>;
  user?: IUser;

  constructor(
    private store: Store<UserState>,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
    this.user$ = store.select(selectCurrentUser);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!isPlatformBrowser(this.platform)) {
      return false;
    }

    return this.user$.pipe(
      take(1),
      map(user => {
        if (user && user?.emailVerifiedAt) {
          return true;
        } else {
          this.router.navigateByUrl('/auth/validate-email');
          return false;
        }
      })
    );
  }
}
