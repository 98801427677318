<div class="d-flex justify-content-between align-items-center user-profile">
  <app-user-profile-img
    [width]="'50px'" [height]="'50px'" [hasLevel]="true"
    [image]="user?.profilePhotoThumbnails?.xsmall"></app-user-profile-img>

  <div class="w-100 color-black ms-2">
    <p class="fs-title-small fw-350">{{'home.hello'|translate}} <span class="fw-900">{{firstName|titlecase}}!</span></p>
    <div *ngIf="poll" ngbDropdown class="d-inline-block" container="body">
      <p class="fs-large clickable fw-500" id="dropdownBasic1" ngbDropdownToggle>
        {{poll.title}}
      </p>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="d-poll-options mt-2">
        <p
          *ngFor="let o of poll.pollOptions;"
          class="clickable option"
          [class.selected]="o?.userPollAnswer"
          (click)="onPollAnswer(o)"
          ngbDropdownItem [innerHTML]="o.optionText|bypassHtmlSanitizer"></p>
      </div>
    </div>
  </div>
</div>
