import { APP_INITIALIZER, LOCALE_ID, NgModule, TransferState } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from "./core/core.module";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient } from "@angular/common/http";
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "@abacritt/angularx-social-login";
import { environment } from "../environments/environment";
import { SharedModule } from './shared/shared.module';
import { provideNgxMask } from "ngx-mask";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { metaReducers, reducers } from "./core/ngrx/app.state";
import { userFeatureKey, userReducer } from "./core/ngrx/reducers/user.reducer";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { ErrorInterceptor } from "./core/interceptors/http-error.interceptor";
import { AuthService } from "./core/services/auth/auth.service";
import { LayoutModule } from "./views/layout/layout.module";
import localeTr from '@angular/common/locales/tr';
import { DatePipe, registerLocaleData } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { examFeatureKey, examReducer } from "./core/ngrx/reducers/exam.reducer";
import { levelFeatureKey, levelReducer } from "./core/ngrx/reducers/level.reducer";
import {
  applicationSettingFeatureKey,
  applicationSettingReducer
} from "./core/ngrx/reducers/application-setting.reducer";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { translateBrowserLoaderFactory } from "./i18n/translate-browser.loader";
import {
  unreadNotificationFeatureKey,
  unreadNotificationReducer
} from "./core/ngrx/reducers/unread-notification.reducer";
import { AbilityModule } from "@casl/angular";
import { AppAbility } from "./core/services/auth/app-ability.service";
import { PureAbility } from "@casl/ability";
import { CanActivateForComponents } from "./core/guards/can-activate-for-components.guard";

registerLocaleData(localeTr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),

    NgbModule,
    AbilityModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreModule.forFeature(userFeatureKey, userReducer),
    StoreModule.forFeature(examFeatureKey, examReducer),
    StoreModule.forFeature(levelFeatureKey, levelReducer),
    StoreModule.forFeature(applicationSettingFeatureKey, applicationSettingReducer),
    StoreModule.forFeature(unreadNotificationFeatureKey, unreadNotificationReducer),

    SocialLoginModule,
    SharedModule,
    LayoutModule,
    InfiniteScrollModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'tr-TR',
    },
    {
      provide: AppAbility,
      useValue: new AppAbility()
    },
    {
      provide: PureAbility,
      useExisting: AppAbility,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleSignInClientId),
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => {
        return () => auth.loadUserInfo();
      },
      deps: [AuthService],
      multi: true
    },
    CanActivateForComponents,
    DatePipe,
    provideNgxMask(),
    provideClientHydration(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
