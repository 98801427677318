import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { finalize, Observable, Subject, take, takeUntil } from "rxjs";
import { IUser } from "../../../../core/models/user/user.model";
import { Store } from "@ngrx/store";
import { UserState } from "../../../../core/ngrx/reducers/user.reducer";
import { isPlatformBrowser } from "@angular/common";
import { selectCurrentUser } from "../../../../core/ngrx/selectors/user.selectors";
import { PollService } from "../../../../core/services/poll/poll.service";
import { IPoll } from "../../../../core/models/poll/poll.model";
import { IPollOption } from "../../../../core/models/poll/poll-option.model";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  user$: Observable<IUser | null>;
  user?: IUser;
  firstName?: string;
  isLoading = false;
  poll?: IPoll;
  isBrowser: boolean;

  constructor(
    private store: Store<UserState>,
    private pollService: PollService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.user$ = store.select(selectCurrentUser);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.user$.pipe(takeUntil(this.destroyed$))
        .subscribe(r => {
          this.user = r as IUser;
          if (this.user?.name) {
            this.firstName = this.user.name.split(' ')[0];
          }
        });
    }
    this.getPoll();
  }

  getPoll() {
    this.isLoading = true;
    this.pollService.getDashboardPoll()
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.poll = r.data;
      });
  }

  onPollAnswer(option: IPollOption) {
    this.pollService.createPollAnswer(this.poll?.id as number, option.id)
      .pipe(take(1))
      .subscribe(r => {
        let poll = this.poll as IPoll;
        poll.pollOptions = poll?.pollOptions?.map(po => {
          return {
            ...po,
            userPollAnswer: (option.id == po.id) ? r.data : null
          };
        });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
