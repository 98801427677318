<div ngbDropdown #notificationDropdown="ngbDropdown" container="body" dropdownClass="nav-notification-dropdown">
  <div
    ngbDropdownToggle class="notifications-nav-item position-relative clickable"
    [class.color-gray-300]="(!unreadNotification) || (unreadNotification <= 0)"
    [class.color-gray-950]="unreadNotification && (unreadNotification > 0)">
    <gray-icon icon="bell"></gray-icon>
    <span
      class="position-absolute start-100 translate-middle bg-danger rounded-circle fw-800"
      [class.d-none]="(!unreadNotification) || (unreadNotification <= 0)"
    >{{unreadNotification ? unreadNotification : ''}}</span>
  </div>

  <div ngbDropdownMenu id="scrollableNotifications" class="overflow-y-auto">

    <div class="col-12 py-2 px-3 mb-2 d-flex justify-content-between  align-items-center notify-dropdown-title">
      <h5 class="color-black fw-800 fs-large">{{'account.notifications'|translate}}</h5>
      <a class="color-gray-300 fw-350 fs-xsmall" routerLink="/dashboard/notifications">{{'buttons.view_all'|translate}}</a>
    </div>

    <app-shared-notification-list *ngIf="notificationDropdown.isOpen()" scrollContainer="#scrollableNotifications"></app-shared-notification-list>
  </div>
</div>
