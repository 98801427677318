import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { JwtService } from "../services/auth/jwt.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private router: Router,
    private translate: TranslateService,
    private toastService: ToastrService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {

          switch (error.status) {
            case 401:      // login
              this.jwtService.destroyToken();
              if (this.router.url.includes('/dashboard')) {
                this.toastService.warning(`${this.translate.instant('toaster.session_is_over')}`);
                this.router.navigateByUrl('/auth/login');
              }
              break;
            case 403:     // forbidden
              /*
                              this.router.navigateByUrl('/unauthorized');
              */
              break;
            case 503:     // forbidden
              this.router.navigateByUrl('/maintenance');
              break;
          }

        }
        return throwError(error);

      })
    );

  }
}
