<div class="landing-wrapper">
  <div class="container position-relative nav-container">
    <nav class="navbar bg-transparent  navbar-expand-lg " id="navbar">

      <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand me-0">
        <img src="{{cdnUri('assets/logo/gks-dark-2.svg')}}" alt="logo" class="img-fluid logo" style="width: 100%;">
      </a>

      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-center">
        <ul class="nav d-flex flex-column flex-md-row">
          <li class="nav-item">
            <button class="nav-link" (click)="scrollToSection()">
              Anasayfa
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" type="button" (click)="scrollToSection('landing-about')">
              Hakkımızda
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" type="button" (click)="scrollToSection('landing-why')">
              SSS
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" type="button" (click)="scrollToSection('landing-pricing')">
              Fiyatlandırma
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" type="button" (click)="scrollToSection('landing-contact')">
              İletişim
            </button>
          </li>
        </ul>
      </div>

      <div class="auth-buttons d-flex gap-2">

        <a routerLink="/auth/login" class="btn btn-sm px-1 py-2 px-lg-3 py-lg-2 landing-light-btn">
          Giriş Yap
        </a>

        <a routerLink="/auth/register" class="btn btn-sm px-1 py-2 px-lg-3 py-lg-2 landing-dark-btn d-md-inline">
          Üye Ol
        </a>

      </div>
    </nav>
  </div>

  <router-outlet></router-outlet>

  <div class="container-fluid mt-5 footer">
    <div class="container">
      <div class="row align-items-end">

        <div class="col-12 col-md-4">
          <img class="footer-logo" src="{{cdnUri('assets/logo/gks-light.svg')}}" alt="logo">

          <div class="contact-info mt-4" id="landing-contact">
<!--            <p class="fw-500 d-flex align-items-center fs-normal mb-3">-->
<!--              <gray-icon class="me-2" icon="location"></gray-icon>-->
<!--            </p>-->

<!--            <p class="fw-500 d-flex align-items-center fs-normal mb-3">-->
<!--              <gray-icon class="me-2" icon="call"></gray-icon>-->
<!--            </p>-->

            <p class="fw-500 d-flex align-items-center fs-normal mb-3">
              <gray-icon class="me-2" icon="clock"></gray-icon>
              Destek Saatleri: 09:00-18:00
            </p>

            <p class="fw-500 d-flex align-items-center fs-normal mb-3">
              <gray-icon class="me-2" icon="landing-mail"></gray-icon>
              info&#64;grikocnavigasyon.com
            </p>
          </div>

        </div>
        <div class="col-12 col-md-4 d-flex align-items-start align-items-md-center flex-column">
          <div class="">
            <h5 class="color-gray-400 fw-800 fs-20 mb-2">Sayfalar</h5>

            <a routerLink="/" class="mb-2 d-block color-white fw-800 fs-normal">Anasayfa</a>
            <a routerLink="/blog" class="mb-2 d-block color-white fw-800 fs-normal">Blog</a>
            <a routerLink="/auth/register" class="mb-2 d-block color-white fw-800 fs-normal">Kayıt Ol</a>
            <a routerLink="/auth/login" class="mb-2 d-block color-white fw-800 fs-normal">Giriş Yap</a>
          </div>


        </div>
        <div class="col-12 col-md-4">

          <div class="">
            <a routerLink="/privacy-policy" class="mb-2 d-block color-white fw-800 fs-normal">Gizlilik Politikası</a>
            <a routerLink="/terms-of-sale" class="mb-2 d-block color-white fw-800 fs-normal">Mesafeli Satış Sözleşmesi</a>
            <a routerLink="/refund-policy" class="mb-2 d-block color-white fw-800 fs-normal">İade Politikası</a>
            <a routerLink="/kvkk-application" class="mb-2 d-block color-white fw-800 fs-normal">Veri Sorumlusuna Başvuru Formu</a>
          </div>


          <div class="payment-info d-flex align-items-center mt-2">
            <img src="{{cdnUri('assets/img/landing/pay-with-iyzico.svg')}}" alt="Grikoç Güvenli Ödeme" />
            <img src="{{cdnUri('assets/img/landing/card-providers.png')}}" alt="Grikoç Güvenli Ödeme" />
          </div>


<!--          <p class="color-gray-50 fw-500 fs-normal">Hemen indirmek için tıkla!</p>-->

<!--          <div class="d-flex mt-4">-->

<!--            <a routerLink="" class="me-3">-->
<!--              <img src="assets/img/landing/appstore-download.svg" alt="Gri Koç App Store">-->
<!--            </a>-->

<!--            <a routerLink="">-->
<!--              <img src="assets/img/landing/playstore-download.svg" alt="Gri Koç Play Store">-->
<!--            </a>-->

<!--          </div>-->
        </div>

      </div>
    </div>

  </div>
</div>
