<div class="level-indicator-container d-flex" *ngIf="(!isLoading)">
  <div class="exam-selector" *ngIf="selectedIndicatorExam">
    <p class="fs-small fw-800" (click)="changeSelected(examKey.TYT)" [class.active]="examKey.TYT == selectedIndicatorExam.examKey">TYT</p>
    <p class="fs-small fw-800" (click)="changeSelected(examKey.AYT)" [class.active]="examKey.AYT == selectedIndicatorExam.examKey">AYT</p>
  </div>
  <div *ngIf="selectedIndicatorExam?.currentLevel" class="level-indicator w-100 {{selectedIndicatorExam?.currentLevel?.key}}">
    <div class="current-level" [style.width]="percentPosition + '%'">

      <div class="score-ranges">
        <div class="score-range" *ngFor="let s of indicatorPreElements;">{{s}}<span>{{'exam.score'|translate}}</span></div>
        <div class="score-range">
          <img src="assets/img/dashboard/suitcase.svg">
        </div>
      </div>

    </div>

      <div class="score-ranges post-ranges" [style.width]="(100 - percentPosition) + '%'">
        <div class="score-range" *ngFor="let s of indicatorPostElements;">{{s}}<span>{{'exam.score'|translate}}</span></div>
      </div>

  </div>

  <div class="level-indicator w-100 no-color" *ngIf="!selectedIndicatorExam?.currentLevel">
    <div class="no-data w-100 d-flex align-items-center justify-content-center">
      <p class="color-gray-950 fw-800 fs-normal text-center clickable" (click)="goToCreateExam()">
        {{'home.indicator_no_exam'|translate}}
      </p>
    </div>
  </div>
</div>
