import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class SearchService {
  private apiPrefix = '/client/search';

  constructor(
    private api: ApiService
  ) {
  }

  search(str: string) {
    return this.api.get(`${this.apiPrefix}?search=${str}`);
  }
}
