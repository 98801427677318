import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import { environment } from "../../../environments/environment";

// tslint:disable-next-line:no-empty-interface
export interface State {

}

export const reducers: ActionReducerMap<State> = {};


export const metaReducers: MetaReducer<State>[] = !environment.isProduction ? [] : [];
