import { Component, OnInit } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { firstValueFrom, Observable, take, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { ExamScoreService } from '../../../../core/services/exam/exam-score.service';
import { selectLevels } from '../../../../core/ngrx/selectors/level.selectors';
import { Store } from '@ngrx/store';
import { ExamState } from '../../../../core/ngrx/reducers/exam.reducer';
import { LevelState } from '../../../../core/ngrx/reducers/level.reducer';
import { selectExams } from '../../../../core/ngrx/selectors/exam.selectors';
import { IExam } from '../../../../core/models/exam/exam.model';
import { ILevel } from '../../../../core/models/user/level.model';
import { selectCurrentUser } from '../../../../core/ngrx/selectors/user.selectors';
import { IUser } from '../../../../core/models/user/user.model';
import { UserState } from '../../../../core/ngrx/reducers/user.reducer';
import { Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { PremiumAccessService } from '../../../../shared/services/premium-access.service';

@Component({
  selector: 'app-level-indicator-two',
  templateUrl: './level-indicator-two.component.html',
  styleUrls: ['./level-indicator-two.component.scss'],
})
export class LevelIndicatorTwoComponent implements OnInit {
  levels!: any[];
  averages!: any[];

  levelData: any;
  level$: any;

  stepCounter = 4;
  steps!: number[];

  positionPercentage: number = 0;

  selectedExam: ('tyt' | 'ayt') = 'tyt';

  exams$: Observable<IExam[] | null>;
  exams: IExam[] = [];
  levels$: Observable<ILevel[] | null>;
  user$: Observable<IUser | null>;

  constructor(private store: Store<ExamState | LevelState | UserState>,
              private examService: ExamScoreService,
              private router: Router,
              private ability: Ability,
              private premiumAccessService: PremiumAccessService) {
    this.exams$ = store.select(selectExams);
    this.levels$ = store.select(selectLevels);
    this.user$ = store.select(selectCurrentUser);
  }

  async ngOnInit() {
    const levels$ = this.levels$.pipe(
      filter(Boolean),
    );

    const exams$ = this.exams$.pipe(filter(Boolean));

    await Promise.all([
      firstValueFrom(levels$),
      firstValueFrom(this.examService.getAverageScores()),
      firstValueFrom(exams$)
    ]).then(([levels, averages, exams]) => {
      this.levels = levels;
      this.averages = averages.data;
      this.exams = exams;
    });

    this.level$ = this.user$.pipe(
      filter(Boolean),
      map(user => user.userInfo?.level?.key),
      tap(level => {
        /*this.levelData = this.levels.find(l => l.key === level);

        this.levelData.minScore = Math.floor(this.levelData.minScore || 0);
        this.levelData.maxScore = Math.ceil(this.levelData.maxScore || 120);

        this.steps = _.range(this.levelData.minScore, this.levelData.maxScore + 1, this.stepCounter);*/

        setTimeout(() => this.selectExam(this.selectedExam), 100);
      }),
    );
  }

  selectedExamIsExist() {
    return (!!this.averages.find(a => a?.exam?.examKey === this.selectedExam));
  }

  selectExam(examKey: ('tyt' | 'ayt')) {
    this.selectedExam = examKey;

    if (!this.averages || !this.averages.length) {
      return false;
    }

    const average = this.averages.find(average => average.exam.examKey === this.selectedExam);

    if (!average?.level){
      return false;
    }

    this.levelData = _.cloneDeep(this.levels.find(l => l.key === average.level.key)) as ILevel;
    const selectedExam = this.exams.find(e => e.examKey === examKey);
    const selectExamScore = this.levelData?.examScores?.find((e: any) => e.examId === selectedExam?.id);

    this.levelData.minScore = Math.floor(selectExamScore.minScore || 0);
    this.levelData.maxScore = Math.ceil(selectExamScore.maxScore || ( examKey == 'tyt' ? 120 : 95));

    this.steps = _.range(this.levelData.minScore, this.levelData.maxScore + 1, (examKey === 'tyt' ? 4 : 3));

    const currentScore = this.averages.find(average => average.exam.examKey === examKey)?.lastAvgScore || this.levelData.minScore;

    this.positionPercentage = 0;

    // For tweening
    setTimeout(() => {
      this.positionPercentage = Math.min(Math.floor(currentScore - this.levelData.minScore) / Math.floor(this.levelData.maxScore - this.levelData.minScore) * 100, 100);

      // UI bug fix.
      this.positionPercentage = this.positionPercentage < 8 ? 8 : this.positionPercentage;
    });

    return true;
  }

  goToCreateExam() {
    if (this.ability.can('access', 'PREMIUM_CONTENT')) {
      this.router.navigate(['/dashboard/exam-analysis/', this.selectedExam], {
        queryParams: {
          create: 1,
        }
      });
    } else {
      this.premiumAccessService.openPremiumContentModal();
    }
  }
}
